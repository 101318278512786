import hoverintent from 'hoverintent';

export const initMenu = () => {
	document.addEventListener("DOMContentLoaded", () => {

		let menuElementsWithSubmenus = document.querySelectorAll('ul li.withSubMenu');

		[...menuElementsWithSubmenus].map(function(el) {
			hoverintent(
				el,
				function() { el.classList.add("intent"); },
				function() { el.classList.remove("intent"); }
			).options({
				sensitivity: 20,
				timeout: 200,
				interval: 40
			});
		});
	});
}
