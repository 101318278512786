import { initMenu } from "./main/menu";
import { initCookie } from "./main/cookie";
import { initCountUp } from './main/countUp';
// import { initSelectStyled } from "./main/select";
import { initAdminMenu } from "./layout/adminMenu";

//import swiper from './main/swiper';

window.initializeScript = function() {
	initCookie();
	initCountUp();
	// initSelectStyled();
	initAdminMenu();
	initMenu();
};

document.addEventListener("DOMContentLoaded", () => {
	// console.log('DOMContentLoaded');
	window.initializeScript();
});
